import React, { useState, useEffect } from 'react';
import Layout from '../components/layout'
  
  export default function Connectors() {
    const [connectors, setConnectors] = useState([]);

    const clearConnectors = () => {
        localStorage.removeItem("appConnectors");
        setConnectors([]);
    }

    useEffect(() => {
        if (localStorage.getItem("appConnectors") !== null) {
            setConnectors(JSON.parse(localStorage.getItem("appConnectors")));
        }
        
      }, [connectors]);

    return (
    <Layout pageTitle="Active Connectors">
        <div className="flex flex-col mt-10">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        Name
                        </th>
                        <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        Status
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {connectors.length > 0 && connectors.map((connector, index) => (
                        <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                                <img className="h-10 w-10 rounded-full" src={connector.connectorLogo} alt="" />
                            </div>
                            <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{connector.connectorName}</div>
                            </div>
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Active
                            </span>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>

        {connectors.length > 0 && 
            <button
                type="button"
                className="inline-flex items-center mt-10 px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => clearConnectors()}
            >
                Clear Connectors
            </button>
        }
      </Layout>
    )
  }